/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0; 

  &.bg-right {
    left: auto;
    right: 0;
  }

  &.overlay:before {
    position: absolute;
    content: "";
    background: rgba(#000, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
 
  &.overlay-0:before {
    background: rgba(#000, 0.7);
  }
  &.overlay-1:before {
    background: rgba(#000, 0.55);
  }
  &.overlay-2:before {
    background: rgba(#000, 0.4);
  }
  &.overlay-3:before {
    background: rgba(#000, 0.88);
  }
  &.overlay-youtube:before{
    background: linear-gradient(0deg, rgba(0, 16, 58, 0.6), rgba(0,16, 58, 0.8)),

  }  

  .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }

  .bg-youtube {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.bg-backdrop{
  position: relative;
  background: rgba(242, 242, 242, 0.4);
  backdrop-filter: blur(1rem);
  border-radius: 16px;
  z-index: 1;
  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    filter: blur(1px);
    border-radius: 16px;
    background: rgba(242, 242, 242, 0.4);
    z-index: -1;
    .firefox &{
      background: rgba(242, 242, 242, 1);
    }
  }
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-glass {
  background-color: rgba($white, 0.9);
}

.bg-card {
  background-size: contain;
  background-position: right;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;

  [dir="rtl"] & {
    background-position: left;
    transform: scaleX(-1);
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
}

/*-----------------------------------------------
|   Background gradient
-----------------------------------------------*/
.bg-line-chart-gradient {
  background-image: var(--#{$variable-prefix}line-chart-gradient);
  background-position: center;
}
.bg-card-gradient {
  background-image: var(--#{$variable-prefix}card-gradient);
  background-position: center;
}
.bg-progress-gradient {
  background-image: var(--#{$variable-prefix}progress-gradient);
  background-position: center;
}
.bg-auth-circle-shape,
.bg-auth-circle-shape-2 {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}
.bg-auth-circle-shape {
  position: absolute;
  right: -8.75rem;
  top: -5.125rem;
}
.bg-auth-circle-shape-2 {
  position: absolute;
  left: -6.25rem;
  bottom: -2.4375rem;
}
.bg-auth-card-shape {
  background-position: 0 100%;
  @include media-breakpoint-up(md) {
    background-position: 0 133%;
  }
}
@mixin shape($bg-color) {
  position: relative;
  overflow: hidden;
  background-color: $bg-color;
  &:after,
  &:before {
    position: absolute;
    content: "";
    border-radius: 50%;
  }
}

.bg-shape {
  @include shape(var(--#{$variable-prefix}bg-shape-bg));
  &:after { background-image: var(--#{$variable-prefix}bg-shape-bg-ltd); }
  &:before { background-image: var(--#{$variable-prefix}bg-shape-bg-dtl); }
}

.bg-circle-shape {
  &:after {
    height: 15.625rem;
    width: 115%;
    left: 32%;
    top: -188%;
  }
  &:before {
    height: 332%;
    width: 45%;
    left: -9%;
    top: 0.5625rem;
  }
}
.modal-shape-header {
  &:before {
    height: 28.9375rem;
    width: 155%;
    right: 23%;
    top: -357%;
  }
  &:after {
    height: 289%;
    width: 45%;
    right: -10%;
    top: 2.5rem;
  }
}
.showcase-page-gradient {
  background: linear-gradient(0deg, rgba(163, 20, 20, 0.5), rgba(27, 27, 27, 0.5));
  mix-blend-mode: normal;
}

.bg-transparent-50 {
  background-color: var(--#{$variable-prefix}transparent-50);
}

.bg-portfolio-img{
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(64px);
  border-radius: 16px;
}



/*-----------------------------------------------
| Footer Background
-----------------------------------------------*/

.bg-soft-blue{
  background: linear-gradient(308.36deg, rgba(106, 17, 250, 0.1) -22.01%, rgba(64, 207, 252, 0.1) 125.61%);
}

.bg-soft-pink{
  background: linear-gradient(311.75deg, rgba(116, 30, 255, 0.1) -18.72%, rgba(255, 55, 187, 0.1) 120.42%);
}

.bg-soft-orange-1{
  background: linear-gradient(309.04deg, rgba(255, 122, 0, 0.1) 0.25%, rgba(250, 255, 10, 0.1) 96%);
}

.bg-soft-orange-2{
  background: linear-gradient(308.36deg, rgba(226, 162, 38, 0.1) -22.01%, rgba(255, 51, 38, 0.1) 125.61%);
}

.bg-soft-green{
  background: linear-gradient(127.61deg, rgba(0, 185, 163, 0.1) -6.08%, rgba(0, 255, 94, 0.1) 125.2%);
}
.bg-soft-indigo{
  background: linear-gradient(311.75deg, rgba(153, 0, 191, 0.1) -18.72%, rgba(10, 25, 172, 0.1) 120.42%);
}

.bg-soft-cyan-1{
  background: linear-gradient(307.88deg, rgba(4, 96, 182, 0.1) -28.4%, rgba(0, 240, 255, 0.1) 128.33%);
}

.bg-soft-cyan-2{
  background: linear-gradient(311.75deg, rgba(59, 124, 250, 0.1) -18.72%, rgba(59, 124, 250, 0.1) -18.71%, rgba(0, 225, 144, 0.1) 120.42%);
}

.bg-soft-danger{
  background: linear-gradient(311.75deg, rgba(205, 12, 0, 0.1) -18.71%, rgba(255, 28, 96, 0.1) 120.42%);
}